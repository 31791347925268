<template>
  <div class="footer-rq">
    <NuxtLink class="link-no-underline" to="/">
    <div class="footer-item">
      <div class="footer-item-icon">
        <i class="iconfont icon-diannao"></i>
      </div>
      <div class="footer-item-title">首页</div>
    </div>
    </NuxtLink>
    <NuxtLink class="link-no-underline" to="/templateCenter">
    <div class="footer-item">
      <div class="footer-item-icon">
        <i class="iconfont icon-mobanzhongxin" style="font-size: 20px;"></i>
      </div>
      <div class="footer-item-title">模板</div>
    </div>
    </NuxtLink>
    <NuxtLink class="link-no-underline" to="/materialCenter">
    <div class="footer-item">
      <div class="footer-item-icon">
        <i class="iconfont icon-sucaizhongxin2" style="font-size: 20px;"></i>
      </div>
      <div class="footer-item-title">素材</div>
    </div>
    </NuxtLink>
    <NuxtLink class="link-no-underline" to="/mycenter">
    <div class="footer-item">
      <div class="footer-item-icon">
        <i class="iconfont icon-accountsettings" style="font-size: 20px;"></i>
      </div>
      <div class="footer-item-title">我的</div>
    </div>
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">

</script>
<style scoped>
.footer-rq{
  position: absolute;
  bottom: 0;
  display: flex;
  width:100%;
  padding: 10px 0;
  border-top: 1px solid #ebeef5;
  background: #fff;
  .link-no-underline{
    width: 25%;
    .footer-item{
      text-align: center;
      .footer-item-icon i{
        font-size: 16px;
      }
    }
  }
}
</style>